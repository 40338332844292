import { render, staticRenderFns } from "./PurchasePolicies.vue?vue&type=template&id=f0ce2afc&scoped=true&"
import script from "./PurchasePolicies.vue?vue&type=script&lang=js&"
export * from "./PurchasePolicies.vue?vue&type=script&lang=js&"
import style0 from "./PurchasePolicies.vue?vue&type=style&index=0&id=f0ce2afc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0ce2afc",
  null
  
)

export default component.exports